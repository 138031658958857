<template>
  <div class="display-block">
    <h3>Role details</h3>
    <v-card>
      <v-card-text>
        <row-item label="Name" :value="role.name"></row-item>
        <row-item label="Description" :value="role.description"></row-item>
        <v-card>
          <row-item label="Permissions"></row-item>
          <v-card-text>
          <v-row>
            <div v-for="userPermission in role.permissions" :key="userPermission.id">
               <v-chip class="ma-2 primary-color-inverted" pill >{{userPermission}}</v-chip>
            </div>
          </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card>
      <v-flex text-right>
      <v-btn class="ma-2" :style="theme" @click="$router.go(-1)">
        Back
      </v-btn>
      </v-flex>
    </v-card>
  </div>
</template>


<script>
import RowItem from '@/components/RowItem'
import appConstants from "@/utils/appConstants.js";

export default {
    components: {
        RowItem,
    },
  data: () => ({
    role: { name: "", description: "", permissions: [] },
    id: 0
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.id = this.$route.params.id;
      try {
        await this.getItem(appConstants.ROLES_API + "/" + this.id).then(response => {
            this.role = response;
          }
        );
      } catch (error) {
        this.handleError(error);
      }
    }
  }
};
</script>
